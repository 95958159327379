import { z } from 'zod';

export const SendEmailVerifyCodeSignUpArgs = z.object({
  _email: z.string().email(),
  _first_name: z.string().nullish(),
});

export type SendEmailVerifyCodeSignUpArgsT = z.infer<
  typeof SendEmailVerifyCodeSignUpArgs
>;

export const SendEmailVerifyCodeSignUpReturns = z.boolean();

export type SendEmailVerifyCodeSignUpReturnsT = z.infer<
  typeof SendEmailVerifyCodeSignUpReturns
>;
