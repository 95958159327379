'use client';

import { useApi } from '@prismo-io/core';
import useSWRMutation from 'swr/mutation';
import type { InformationsStepSchemaT } from '../schema';
import { sendVerificationCode } from './send-verification-code';

export const useSendVerificationCode = (
  data: Pick<InformationsStepSchemaT, 'email'> &
    Partial<Pick<InformationsStepSchemaT, 'firstName'>>
) => {
  const { client } = useApi();

  return useSWRMutation(['send-verification-code'], (key) =>
    sendVerificationCode(client, data)
  );
};
