import { t } from '@lingui/macro';
import type { ApiClient } from '@prismo-io/schemas';
import { z } from 'zod';

export const CheckIfUserEmailIsProSchema = z.object({
  _email: z.string().email().trim().min(1, t`L'email est obligatoire.`),
});

export type CheckIfUserEmailIsProSchemaT = z.infer<
  typeof CheckIfUserEmailIsProSchema
>;

export const checkIfUserEmailIsPro = async (
  client: ApiClient,
  data: CheckIfUserEmailIsProSchemaT
) => {
  const arg = CheckIfUserEmailIsProSchema.parse(data);

  const { data: isMailPro } = await client
    .rpc('check_if_email_is_pro_for_verification', arg)
    .select('*')
    .maybeSingle();

  if (!isMailPro) return false;

  return isMailPro;
};
