'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { Button, Stack } from '@mui/material';
import { TextFieldInput } from '@prismo-io/design-system';
import type { FC } from 'react';
import { type DeepPartial, type SubmitHandler, useForm } from 'react-hook-form';
import {
  ApplyRestrictDomainAndEmailRefine,
  InformationsStepSchema,
  type InformationsStepSchemaT,
} from '../schema';

type SignUpInformationsStepProps = {
  defaultValues?: DeepPartial<InformationsStepSchemaT>;
  onSubmit: SubmitHandler<InformationsStepSchemaT>;
  haveNextStep?: boolean;
  configuration?: {
    withRestrictDomain?: boolean;
    restrictDomain?: string[];
    withRestrictEmail?: boolean;
    restrictEmail?: string[];
  };
};

const DEFAULT_VALUES: InformationsStepSchemaT = {
  email: '',
  firstName: '',
  lastName: '',
  with_restrict_domain: false,
  restrict_domain: [],
  with_restrict_email: false,
  restrict_email: [],
};

export const SignUpInformationsStepForm: FC<SignUpInformationsStepProps> = (
  props
) => {
  const {
    onSubmit,
    haveNextStep = false,
    defaultValues = {},
    configuration = {},
  } = props;

  const {
    withRestrictDomain = false,
    restrictDomain = [],
    withRestrictEmail = false,
    restrictEmail = [],
  } = configuration;

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<InformationsStepSchemaT>({
    mode: 'all',
    // resolver: zodResolver(ApplyRestrictDomainRefine(InformationsStepSchema())),
    resolver: zodResolver(
      ApplyRestrictDomainAndEmailRefine(InformationsStepSchema())
    ),
    defaultValues: {
      ...DEFAULT_VALUES,
      with_restrict_domain: withRestrictDomain,
      restrict_domain: restrictDomain,
      with_restrict_email: withRestrictEmail,
      restrict_email: restrictEmail,
      ...defaultValues,
    },
  });

  return (
    <Stack
      spacing={2}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextFieldInput
        control={control}
        name="firstName"
        label={t`Prénom`}
        type="text"
        autoComplete="given-name"
        size="small"
        required
      />
      <TextFieldInput
        control={control}
        name="lastName"
        label={t`Nom`}
        type="text"
        autoComplete="family-name"
        size="small"
        required
      />
      <TextFieldInput
        control={control}
        disabled={!!defaultValues['email']}
        name="email"
        label={t`Email`}
        type="email"
        autoComplete="email"
        size="small"
        required
      />
      <Button
        type="submit"
        disabled={!isValid}
        variant="contained"
        color="primary"
      >
        {haveNextStep ? t`Suivant` : t`M'inscrire`}
      </Button>
    </Stack>
  );
};
