'use client';

import { useApi } from '@prismo-io/core';
import useSWR from 'swr';
import { checkIfUserEmailIsPro } from './check-if-user-email-is-pro';

export const useCheckIfUserEmailIsPro = (
  email: string | undefined = undefined
) => {
  const { client } = useApi();

  return useSWR(
    () => (email ? ['check-user-email-is-pro', email] : null),
    (key) => checkIfUserEmailIsPro(client, { _email: email as string })
  );
};
