import {
  type ApiClient,
  SendEmailVerifyCodeSignUpArgs,
} from '@prismo-io/schemas';
import type { InformationsStepSchemaT } from '../schema';

export const sendVerificationCode = async (
  client: ApiClient,
  params: Pick<InformationsStepSchemaT, 'email'> &
    Partial<Pick<InformationsStepSchemaT, 'firstName'>>
): Promise<boolean> => {
  const args = SendEmailVerifyCodeSignUpArgs.parse({
    _email: params.email,
    _first_name: params.firstName,
  });

  await client.rpc('send_email_verify_code_sign_up', args);

  return true;
};
