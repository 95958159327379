import { t } from '@lingui/macro';
import {
  type ApiClient,
  CheckEmailVerifyCodeArgs,
  type CheckEmailVerifyCodeArgsT,
} from '@prismo-io/schemas';

export const checkVerificationCode = async (
  client: ApiClient,
  params: CheckEmailVerifyCodeArgsT
): Promise<boolean> => {
  const args = CheckEmailVerifyCodeArgs.parse(params);

  const { data } = await client
    .rpc('check_email_verify_code', args)
    .maybeSingle();

  if (!data) {
    throw new Error(t`Le code n'est pas valide.`);
  }

  return true;
};
