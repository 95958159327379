import { t } from '@lingui/macro';
import { ComingFromType } from '@prismo-io/schemas';
import { z } from 'zod';

export const InformationsStepSchema = () =>
  z.object({
    firstName: z
      .string({
        required_error: t`Le prénom est obligatoire.`,
        invalid_type_error: t`Le prénom doit être valide.`,
      })
      .trim()
      .min(2, t`Le prénom doit contenir au moins 2 caractères.`),
    lastName: z
      .string({
        required_error: t`Le nom est obligatoire.`,
        invalid_type_error: t`Le nom doit être valide.`,
      })
      .trim()
      .min(2, t`Le nom doit contenir au moins 2 caractères.`),
    email: z
      .string({ required_error: t`L'email est obligatoire.` })
      .trim()
      .email(t`L'email n'est pas valide`)
      .min(1, t`L'email est obligatoire.`),
    with_restrict_domain: z.boolean(),
    restrict_domain: z.string().trim().array().nullish().optional(),
    with_restrict_email: z.boolean(),
    restrict_email: z.string().trim().array().nullish().optional(),
  });

export type InformationsStepSchemaT = z.infer<
  ReturnType<typeof InformationsStepSchema>
>;

// export const ApplyRestrictDomainRefine = <
//   T extends ReturnType<typeof InformationsStepSchema> = ReturnType<
//     typeof InformationsStepSchema
//   >
// >(
//   schema: T
// ) => {
//   return schema.refine(
//     (data) => {
//       if (
//         !data.with_restrict_domain ||
//         !data.restrict_domain ||
//         data.restrict_domain.length === 0
//       ) {
//         return true;
//       }

//       const formatedDomains = data.restrict_domain
//         .map((domain) => (domain.startsWith('@') ? domain : `@${domain}`))
//         .filter(
//           (domain) => typeof domain === 'string' && domain.trim() !== ''
//         ) as string[];

//       return formatedDomains.some((formatedDomain) =>
//         data.email.endsWith(formatedDomain)
//       );
//     },
//     (data) => {
//       if (
//         !data.with_restrict_domain ||
//         !data.restrict_domain ||
//         (data.restrict_domain && data.restrict_domain.length === 0)
//       ) {
//         return z.NEVER;
//       }

//       const formatedDomain = data.restrict_domain
//         .map((domain) => (domain.startsWith('@') ? domain : `@${domain}`))
//         .filter((domain) => typeof domain === 'string' && domain.trim() !== '')
//         .map((domain) => `"${domain}"`)
//         .join(', ');

//       return {
//         message: t`L'email doit terminer par ${formatedDomain}.`,
//         path: ['email'],
//       };
//     }
//   );
// };

// .merge(
//   z.discriminatedUnion(
//     'with_restrict_domain',
//     [
//       z.object({
//         with_restrict_domain: z.literal(false),
//       }),
//       z.object({
//         with_restrict_domain: z.literal(false),
//         // restrict_domain: z.literal(domain.startsWith('@') ? domain : `@${domain}`),
//         email: z
//           .string({ required_error: t`L'email est obligatoire.` })
//           .trim()
//           .email(t`L'email n'est pas valide`)
//           .min(1)
//           // .endsWith(domain.startsWith('@') ? domain : `@${domain}`, t`L'email doit terminer par "${domain.startsWith('@') ? domain : `@${domain}`}".`),
//       }),
//     ]
//   )
// );

export const ApplyRestrictDomainAndEmailRefine = <
  T extends ReturnType<typeof InformationsStepSchema> = ReturnType<
    typeof InformationsStepSchema
  >,
>(
  schema: T
) => {
  return schema.refine(
    (data) => {
      if (
        data.with_restrict_domain &&
        !!data.restrict_domain &&
        data.restrict_domain.length > 0
      ) {
        const formatedDomains = data.restrict_domain
          .map((domain) => (domain.startsWith('@') ? domain : `@${domain}`))
          .filter(
            (domain) => typeof domain === 'string' && domain.trim() !== ''
          ) as string[];

        return formatedDomains.some((formatedDomain) =>
          data.email.endsWith(formatedDomain)
        );
      }

      if (
        data.with_restrict_email &&
        !!data.restrict_email &&
        data.restrict_email.length > 0
      ) {
        return data.restrict_email.some((email) => data.email === email);
      }

      return true;
    },
    (data) => {
      if (
        data.with_restrict_domain &&
        !!data.restrict_domain &&
        data.restrict_domain.length > 0
      ) {
        const formatedDomain = data.restrict_domain
          .map((domain) => (domain.startsWith('@') ? domain : `@${domain}`))
          .filter(
            (domain) => typeof domain === 'string' && domain.trim() !== ''
          )
          .map((domain) => `"${domain}"`)
          .join(', ');

        return {
          message: t`L'email doit terminer par ${formatedDomain}.`,
          path: ['email'],
        };
      }

      if (
        data.with_restrict_email &&
        !!data.restrict_email &&
        data.restrict_email.length > 0
      ) {
        return {
          message: t`L'email ne correspond pas à ceux spécifiés dans l'invitation.`,
          path: ['email'],
        };
      }

      return z.NEVER;
    }
  );
};

export const SecurityStepSchema = () =>
  z.object({
    password: z
      .string({
        required_error: t`Le mot de passe est obligatoire.`,
        invalid_type_error: t`Le mot de passe doit être valide.`,
      })
      .trim()
      .min(8, t`Le mot de passe doit contenir au moins 8 caractères.`)
      .regex(/[0-9]/, t`Le mot de passe doit contenir au moins 1 chiffre.`)
      .regex(/[a-z]/, t`Le mot de passe doit contenir au moins 1 minuscule.`)
      .regex(/[A-Z]/, t`Le mot de passe doit contenir au moins 1 majuscule.`),
    confirmPassword: z
      .string({
        required_error: t`Veuillez confirmer votre mot de passe.`,
        invalid_type_error: t`Veuillez confirmer votre mot de passe.`,
      })
      .trim()
      .min(1, t`Veuillez confirmer votre mot de passe.`),
    terms: z.coerce.boolean().refine((isAccepted) => isAccepted === true, {
      message: t`Vous devez accepter les conditions`,
    }),
  });

export type SecurityStepSchemaT = z.infer<
  ReturnType<typeof SecurityStepSchema>
>;

export const ApplyPasswordRefine = <
  T extends ReturnType<typeof SecurityStepSchema> = ReturnType<
    typeof SecurityStepSchema
  >,
>(
  schema: T
) => {
  return schema.refine((data) => data.password === data.confirmPassword, {
    message: t`Les mots de passe ne correspondent pas.`,
    path: ['confirmPassword'],
  });
};

export const VerificationCodeSchema = () =>
  z.object({
    verificationCode: z.discriminatedUnion('with_verification', [
      z.object({
        with_verification: z.literal(true),
        code: z
          .string()
          .trim()
          .length(6, t`Le code de vérification doit comporter 6 chiffres.`),
      }),
      z.object({
        with_verification: z.literal(false),
      }),
    ]),
  });

export type VerificationCodeSchemaT = z.infer<
  ReturnType<typeof VerificationCodeSchema>
>;

export const SignUpFormSchema = InformationsStepSchema()
  .merge(SecurityStepSchema())
  .merge(VerificationCodeSchema())
  .merge(
    z.object({
      comingFrom: ComingFromType.nullish().optional(),
      campaign: z.string().trim().nullish().optional(),
      community_variation: z.string().trim().nullish().optional(),
    })
  );

export type SignUpFormSchemaT = z.infer<typeof SignUpFormSchema>;
