import { z } from 'zod';

export const CheckEmailVerifyCodeArgs = z.object({
  _email: z.string().email(),
  _code: z.string().length(6),
});

export type CheckEmailVerifyCodeArgsT = z.infer<
  typeof CheckEmailVerifyCodeArgs
>;

export const CheckEmailVerifyCodeReturns = z.boolean();

export type CheckEmailVerifyCodeReturnsT = z.infer<
  typeof CheckEmailVerifyCodeReturns
>;
