import { Paper } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type { FC, ReactNode } from 'react';

type SignPaperCardProps = {
  children: ReactNode;
};

export const SignPaperCard: FC<SignPaperCardProps> = (props) => {
  const { children } = props;

  return (
    <Paper
      elevation={3}
      sx={(theme: Theme) => ({
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
          borderRadius: 1,
          width: '100%',
          maxWidth: '26rem',
          height: 'auto',
        },
      })}
    >
      {children}
    </Paper>
  );
};
